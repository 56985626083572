.about-page-container {
  position: relative;
}

.app-description-container {
  font-size: 1.8vw;
  width: 80vw;
  position: absolute;
  top: 10vw;
  left: 10%;
  letter-spacing: 0.06em;
  word-spacing: 0.3em;
  line-height: 1.3em;
  text-align: justify;
  visibility: hidden;
}

.app-description {
  text-indent: 50px;
}

.team-members-info {
  margin-top: 4vw;
  width: inherit;
}

.app-description,
.team-members-info,
.source-code-btn {
  animation: fadeIn ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

.member-box {
  display: grid;
  width: inherit;
  grid-template-columns: auto auto auto auto;
  column-gap: 12%;
  letter-spacing: 0.1em;
}

.member-name {
  font-size: 1.7vw;
  text-align: center;
}

.member-pos {
  font-size: 1.4vw;
  text-align: center;
  margin-top: 0.5vw;
  margin-bottom: 1.5vw;
}

.member-avatar {
  cursor: pointer;
  box-sizing: border-box;
  
}

.member-avatar:hover {
  border: 5px solid #fff;
  box-shadow: 0px 0px 10px #fff;
}

.member-avatar:hover {
  color: #fff;
  text-shadow: 0px 0px 3px #fff;
}

.source-code-btn {
  position: absolute;
  left: 46%;
  text-decoration: none;
  color: inherit;
  padding: 0.5em 0.8em;
  font-size: 1vw;
  border-radius: 5px;
  border: 1px solid #9DBEBB;
  transition: color 0.5s;
  transition: background-color 0.5s;
}

.source-code-btn:hover {
  background-color: #9DBEBB;
  color: #031926;
}

@media screen and (width > 1440px) {
  .source-code-btn {
    bottom: -47vw;
  }
}

@media screen and (width <= 1440px) {
  .source-code-btn {
    bottom: -49vw;
  }
}

.online-presence-links {
  text-align: center;
  margin-top: 1vw;
}

.online-presence-links a {
  text-decoration: none;
  color: #F4E9CD;
  display: inline-block;
  width: 3vw;
}

.online-presence-links a:hover {
  color:#9DBEBB;
}