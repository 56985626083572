.container{
    /* background-color: blue; */
    margin-top: 5rem;
    margin-bottom: 5rem;

}

.problems-container{
    margin-top: 2rem;
    width: 100%;
    /* text-align: center; */
/* background-color: antiquewhite; */
}

.box{
    position: relative;
    width: 50%;
    background: linear-gradient(to bottom, #9dbebb 0%, #468189 50%, #9dbebb 100%);
    /* box-shadow: 2px 2px 2px 2px #F3E9CD(0, 0, 0, 0.2);  */
    background-color: #9dbebb;
    padding: 1rem;
    margin: auto;
    border-radius: 1rem;
    border: none;
    transition: box-shadow 0.5s;
    animation-duration: 6s;
    animation-iteration-count: infinite;

}

.noproblem{
    width: 50%;
    margin: 20vw auto;
    font-size: 2em;
}

.catgory{
    font-size: 1.2vw;
    color:#031926;
    text-align: center;
}

.add-offer-btn{
    margin: auto;
    padding: .5rem;
    width: 15%;
    border-radius: 5px;
    color: #031926;
    font-size: 1vw;
    font-family: 'Rakkas', cursive;
    border: none;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.75);
    transition: background-color 0.5s ease-in 100ms;
}

.add-offer-btn:hover {
    background-color: white;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9);
}

.des-box{
    padding-top: 1rem;
    padding-bottom: .5rem;
    text-align: center;
    font-size: 1.6vw;
    /* border-bottom: 3px #031926 solid; */
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
}

.title{
    text-align: center;
}

.offer{
    width: 100%;
    /* background-color: aliceblue; */
    display: flex;
    justify-content: center;

}

.status{
    /* background-color: aliceblue; */
    color: green !important;
    text-align: center;
    border-bottom: 3px #031926 solid;
    padding-bottom: 1rem;
    font-size: 1.2vw;
}

.user{
    cursor: pointer;
    text-align: center;
    font-size: 1.2vw;
}

.all-req-content {
    color: #031926;
    line-height: 1.3em;
}

.problem-edit-delete {
    left: 41.5vw !important;
}

.edit-zipcode-input::placeholder {
    font-size: large;
}

.edit-request-btn {
    width: fit-content !important;
}

.edit-description-input {
    resize: horizontal;
}

.problem-created {
    font-size: 0.9vw;
    color: #031926;
}