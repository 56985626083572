.nav-bar-container {
  /* background-color: #3498db; */
  /* margin-top: .5vw; */
  margin-right: 1vw;
  margin-left: 1vw;
  position: relative;
  color: inherit;
  z-index: 10;
}

.logo-container {
  width: 4vw;
  height: 4vw;
  position: absolute;
  top: 1.3vw;
}

.main-pg-logo,
.main-pg-logo2 {
  width: inherit;
  height: inherit;
}

.main-pg-logo {
  cursor: pointer;
}

.links-auth {
  position: absolute;
  right: 1vw;
  top: 1vw;
  color: inherit;
}

.nav-btn {
  --c: #9DBEBB;
  text-decoration: none;
  color: inherit;
  display: inline-block;
  border: 1px solid var(--c);
  margin-top: 1vw;
  padding: 0.5vw 0.7vw;
  font-size: 1.3vw;
  border-radius: 5px;
  width: 5vw;
  text-align: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  word-spacing: 0.3em;
  letter-spacing: 0.05em;
}

.nav-btn:first-child,
.nav-btn:nth-child(2),
.nav-btn-gp2:first-child,
.nav-btn-gp2:nth-child(2) {
  margin-right: 1vw;
}

.nav-btn span,
.nav-btn-gp2 span {
  position: absolute;
  width: 52%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 1s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.nav-btn:hover,
.nav-btn-gp2:hover,
.nav-btn:hover,
#about-btn:hover {
  color: #031926;
}

.nav-btn:hover span,
.nav-btn-gp2:hover span {
  transform: translateY(0) scale(2);
}

.nav-btn span:first-child,
.nav-btn-gp2 span:first-child {
  --n: 3;
}

.nav-btn span:nth-child(2),
.nav-btn-gp2 span:nth-child(2) {
  --n: 2;
}

.nav-btn span:nth-child(3),
.nav-btn-gp2 span:nth-child(3) {
  --n: 6;
}

.nav-btn span:nth-child(4),
.nav-btn-gp2 span:nth-child(4) {
  --n: 1;
}

#about-btn {
  position: absolute;
  color: inherit;
  right: 80vw;
}

.links-nav {
  position: relative;
  right: 1vw;
  top: 1vw;
  color: inherit;
}

.nav-btn-gp2 {
  --c: #9DBEBB;
  text-decoration: none;
  color: inherit;
  display: inline-block;
  border: 1px solid var(--c);
  margin-top: 1vw;
  font-size: 1.3vw;
  padding: 0.5vw 0.7vw;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  word-spacing: 0.3em;
  letter-spacing: 0.05em;
}

.all-requests-btn {
  position: absolute;
  right: 78vw;
  width: 8vw;
}

.new-request-btn {
  position: absolute;
  right: 66vw;
}

.notify-btn {
  position: relative;
  right: -81vw;
  top: 2.5vw;
}

.notify-icon {
  transition: box-shadow 2s;
  border-radius: 5px;
}

.red {
  /* color: red; */
}

.white {
  color: white
}

.notify-icon:hover {
  box-shadow: 0px 0px 5px 3px #fff;
}

.user-profile-btn {
  position: absolute;
  right: 5vw;
}

@media screen and (width < 800px) {
  .user-profile-btn {
    right: 4vw;
  }
}

@media screen and (width < 600px) {
  .user-profile-btn {
    right: 1vw;
  }
}

.logout-btn {
  position: relative;
  left: 94vw;
  top: 2.45vw;
}

.logout-icon,
.nav-chat-icon {
  transition: box-shadow 2s;
}

.logout-icon:hover,
.nav-chat-icon:hover {
  box-shadow: 0px 0px 5px 3px #fff;
}

.nav-chat-btn {
  position: relative;
  right: -76vw;
}

.nav-chat-icon {
  border-radius: 5px;
}

@media screen and (width > 1440px) {
  .nav-chat-btn {
    top: 2.9vw;
  }
}

@media screen and (width <= 1440px) {
  .nav-chat-btn {
    top: 2.65vw;
  }
}

@media screen and (width <= 1300px) {
  .nav-chat-btn {
    top: 2.50vw;
  }
}

@media screen and (width <= 1050px) {
  .nav-chat-btn {
    top: 2vw;
    right: -74vw;
  }
}

@media screen and (width < 800px) {
  .nav-chat-btn {
    top: 1.65vw;
  }
}

@media screen and (width < 600px) {
  .nav-chat-btn {
    top: 0.65vw;
    right: -72vw;
  }
}

.private-chat-history {
  width: fit-content;
  height: fit-content;
  position: absolute;
  color: #F4E9CD;
  background-color: rgba(70, 129, 137, 0.85);
  top: 4vw;
  font-size: 1.1vw;
  border-radius: 5px;
  padding: 0.5em;
  z-index: 10;
  letter-spacing: 0.05em;
  border: 1px solid #F4E9CD;
}

@media screen and (width > 1440px) {
  .private-chat-history {
    right: 15.3vw;
  }
}

@media screen and (width: 1440px) {
  .private-chat-history {
    right: 14.5vw;
  }
}

@media screen and (width < 1440px) {
  .private-chat-history {
    right: 14vw;
  }
}

@media screen and (width < 800px) {
  .private-chat-history {
    right: 12vw;
  }
}

@media screen and (width < 600px) {
  .private-chat-history {
    right: 10vw;
  }
}

.private-chat-history ul li {
  padding: 0.3em;
  border-bottom: 1px solid #F4E9CD;
}

.private-chat-history ul li:last-child {
  border-bottom: none;
}

.private-chat-history ul li:hover {
  background-color: rgba(5, 46, 70, 0.65);
  color: #FEFCFB;
}

.private-chat-history ul li:hover .private-chat-other-username {
  color: #FEFCFB;
}

.private-chat-link {
  text-decoration: none;
  color: inherit;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
}

.private-chat-title {
  font-weight: bold;
  margin-bottom: 0.1em;
  border-bottom: 2px solid #F4E9CD;
}

.private-chat-link:active {
  color: inherit;
}

.private-chat-other-username {
  color: #F4E9CD;
}

.private-chat-link .enter-private-chat {
  text-align: right;
}

.enter-chat-icon {
  position: relative;
  top: 0.05em;
}

.scrollable-chat-container {
  max-height: 40vh;
  overflow-y: auto;
}

@media screen and (width > 1440px) {
  .logout-btn, .notify-btn {
    top: 2.8vw;
  }
}

@media screen and (width < 1200px) {
  .logout-btn, .notify-btn {
    top: 1.9vw;
  }

  .logout-btn {
    left: 93vw;
  }
}

@media screen and (width < 800px) {
  .logout-btn, .notify-btn {
    top: 1.5vw;
  }

  .logout-btn {
    left: 91vw;
  }

  .user-profile-btn {
    right: 8vw;
  }

  .notify-btn {
    right: -77vw;
  }

}

@media screen and (width < 600px) {
  .logout-btn, .notify-btn {
    top: 0.5vw;
  }

  .logout-btn {
    left: 87vw;
  }

  .user-profile-btn {
    right: 7vw;
  }
  
  .notify-btn {
    right: -75vw;
  }
}

.selected-nav-btn {
  color: #031926 !important;
  background-color: #9DBEBB;
}

.space{
  padding-bottom: 3rem;
}

.select {
  color: #666;
  font-size: 1.3vw;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  background-color: #9DBEBB;
  width: 100%
}

#category {
  color: #666;
  width: 75%
}

/* Style the options */
#option {
  background-color: #ffffff;
  /* Set the background color for options */
}

/* Style the options on hover */
option:hover {
  background-color: #e0e0e0;
  /* Change background color on hover */
}

.selecr-font {
  color: #666;
  font-size: 1.3vw;
}

select:focus,
textarea:focus {
  box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 1);
}

select,
textarea {
  outline: none;
  transition: box-shadow 0.5s;
  max-width: 100%;
}

.custom-file-input {
  display: inline-block;
  padding: 8px 12px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
}

.custom-file-input-label {
  border: none;
  outline: none;
  /* You can add more styling here as needed */
}

.img-input {
  padding: 1rem;
  color: #666;
  background-color: #9DBEBB;
  width: 15rem;
  border-radius: 5px;
  border: none;
  font-size: large;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 3rem;
  cursor: pointer;
}

/* Specific rule for input[type="file"] */
input[type="file"] {
  display: none;
}

.offerbox{
  /* background-color: #F3E9CD; */
  width:100%;
  height: 100vh;
  overflow:auto;
}

.offerbox::-webkit-scrollbar {
  display: none;
}

.zipcode-input::placeholder {
  font-size: large;
}

.add-request-btn {
  width: fit-content !important;
}

.add-request-description-box {
  resize: horizontal;
}

/* #selected-requests-btn,
#selected-profile-btn,
.selected-about-btn {
  background-color: #9DBEBB;
  color: #031926;
} */


.shaking {
  animation: shake 0.60s ease-in-out infinite, changeColor 0.60s alternate infinite;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-3px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(3px);
  }
}

@keyframes changeColor {
  from {
    /* color: red; */
  }
  to {
    color: #F4E9CD; /* Change it to the original color or any other color */
  }
}

.request-preview-img-container {
  max-width: 80%;
  width: 70%;
  height: 20%;
  margin: 0 auto;
}

.request-preview-image {
  width: inherit;
  height: inherit;
  object-fit: contain;
}