/* Private chat styling */
.chat-box-container {
  width: 40vw;
  max-width: 50vw;
  height: 60vh;
  margin-top: 18vh;
  background-color: #304453;
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border-radius: 5px;
}

@media screen and (width < 1440px) {
  .chat-box-container {
    width: 48vw;
  }
}

@media screen and (width: 1440px) {
  .chat-box-container {
    width: 45vw;
  }
}

.chat-user {
  margin-bottom: 1em;
  margin-left: 90%;
  font-size: 1.2vw;
}

.scrollable-chat {
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  color: #031926;
  font-size: 1.3vw;
  font-weight: 500;
  height: 47vh;
  /* grid-gap: 0.6rem; */
  padding-top: 1vh;
  border-top: 2px solid #999;
}

.chat {
  row-gap: 0;
  display: grid;
}

.scrollable-chat::-webkit-scrollbar {
  display: none;
}

.sent, .received {
  width: fit-content;
  max-width: 75%;
  height: fit-content;
  margin-bottom: 1em;
  word-wrap: break-word;
  border: 2px solid #031926;
  padding: 0.2em 1em;
  word-spacing: 0.05em;
  letter-spacing: 0.01em;
}

.sent {
  background-color: #6BBBAE;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-left: 60%;
  justify-self: right;
}

.received {
  background-color: #CADFF1;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  justify-self: left;
}

.sticky-input {
  position: absolute;
  bottom: 1.2vw;
  width: inherit;
}

.sticky-input form {
  width: inherit;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 88% 12%;
  height: 5vh;
  font-size: 1.5vw;
}

@media screen and (width < 1000px) {
  .sticky-input form {
    height: 3vh;
  }
}

.sticky-input form input:focus {
  box-shadow: none !important;
}

.sticky-input form input {
  font-size: inherit;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 0.3em;
}

.sticky-input form button {
  font-size: inherit;
  background-color: #9DBEBB;
  outline: none;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.sticky-input form button:hover {
  background-color: rgba(115, 182, 230, 0.9);
}

.typing-message-private-container {
  width: max-content;
  position: absolute;
  font-size: 1vw;
}

@media screen and (width > 1440px) {
  .typing-message-private-container {
    bottom: 4.0vw;
  }
}

@media screen and (width <= 1440px) {
  .typing-message-private-container {
    bottom: 4.3vw;
  }
}

/* Public chat styling */
/* .public-chat-msg {
  font-size: 1.3vw;
  background-color:rgba(192, 192, 192, 0.8);
  margin-bottom: 0.5em;
  border-radius: 5px;
  padding: 0.3em 0.6em;
  width: fit-content;
} */