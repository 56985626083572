#modal{
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
}
#modal-background{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}
#modal-offer{
    position: absolute;
    /* background-color:white; */
    width: 60%;
    padding-bottom: 3rem;
    /* height: 37rem; */
    border: 5px bold black;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);    /* left: 32%;
    top:11%; */
    margin: auto;
    text-align: center;
    border-radius: 30px;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    /* background-color: antiquewhite; */

}
.display_none{
    display: none;
}

