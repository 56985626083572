.session-form{
    background-color: antiquewhite;
    position: relative;
}

.sign-up-form-container {
    position: absolute;
    width: 100%;
    top: 5vw;
}

.sign{
    margin: auto;
/* margin-top: 10%; */
    width: 30%;
    background: linear-gradient(to bottom, #031926 0%, #77ACA2 50%, #031926 100%);
    /* background-color: rgba(119, 172, 162, 1); */
    /* border-radius: 1rem; */
    padding: 2rem;
    text-align: center;
}

.sign-up-title{
    width: 39%;
    padding-bottom: 1rem;
    padding-top: 3rem;
    /* background-color: aqua; */
    text-align: center;
    margin: auto;
    font-size: 2.5vw;
    color: #F4E9CD;
    word-spacing: 1vw;
}

.signup-input{
    padding: 0.8rem;
    background-color: #9DBEBB;
    margin-bottom: 1rem;
    min-width: 70%;
    width: 70%;
    border-radius: 5px;
    border: none;
    font-size: large;
    color:black;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
}

input::placeholder {
    color: #666;
    font-size: 1.3vw;
}

input[type='text'], 
input[type='password'],
.signup-zipcode,
.zipcode-input,
.edit-zipcode-input,
.price-input {
    outline: none;
    transition: box-shadow 0.5s;
}

input[type='text']:focus,
input[type='password']:focus,
.signup-zipcode:focus,
.zipcode-input:focus,
.edit-zipcode-input:focus,
.price-input:focus {
    box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 1);
}

.sign-up-btn{
    /* padding: .7rem; */
    width: 35%;
    border-radius: 5px;
    color: #031926;
    font-size: 1.8vw;
    font-family: 'Rakkas', cursive;
    border: none;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.75);
    transition: background-color 0.5s ease-in 100ms;
    word-spacing: 0.3em;
}

.sign-up-btn:hover, .sign-up-btn:focus-visible {
    background-color: white;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9);
}

.sign-up-btn:focus-visible {
    outline: none;
}

#register-btn {
    margin-top: 4vh;
}

#profile-img-btn {
    font-size: 1.3vw;
    cursor: pointer;
}

.user-profile-preview-container {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vh;
}

.user-profile-preview {
    width: inherit;
    object-fit: contain;
}

.sign-up-form-container:has(.user-profile-preview-container) {
    top: 2.5vw;
}

.sign-up-form-container:has(.user-profile-preview-container) #register-btn {
    margin-top: 1vh;
}

.sign-in-form-container {
    position: absolute;
    width: 100%;
    top: 5vw;
}

.sign-in-title{
    width: 30%;
    padding-bottom: 1rem;
    padding-top: 7rem;
    /* background-color: aqua; */
    text-align: center;
    margin: auto;
    font-size: 2.5vw;
    color: #F4E9CD;
    word-spacing: 0.8vw;
}

.title{
    font-size: 2vw;
    line-height: 1.2em;
    margin-bottom: 1em;
}

.register:hover {
    color: white;
}

.register{
    color: rgba(255, 255, 255, 0.5);   
    text-decoration: none;
}

.logo{
    width: 4.5vw;
    height: 4.5vw;
    position: absolute;
    /* margin-bottom: -1rem; */
}

.btn{
    font-size: x-large;
}
