/* pg stands for profile page instead of pp */

.pg-container {
  position: absolute;
  top: 7.5vw;
  padding: 0px 2vw;
  display: grid;
  grid-template-columns: 20vw 55vw 20vw;
  column-gap: 1%;
  height: 80vh;
  color: inherit;
  letter-spacing: 0.05vw;
}

@media screen and (width > 1440px) {
  .pg-container {
    top: 6vw;
    height: 80vh;
  }
}

@media screen and (width <= 1300px) {
  .pg-container {
    height: 82vh;
  }
}

@media screen and (width <= 1100px) {
  .pg-container {
    height: 84vh;
  }
}

@media screen and (width <= 800px) {
  .pg-container {
    height: 86vh;
  }
}

@media screen and (width <= 600px) {
  .pg-container {
    height: 90vh;
  }
}

.pg-left-side-bar,
.pg-middle-section,
.pg-right-side-bar {
  padding: 1vw;
}

.pg-left-side-bar {
  border-right: 1px solid #fff;
  font-size: 1.3vw;
}

.pg-filter-btn {
  margin-bottom: 3vw;
  padding: 1em;
  cursor: pointer;
  transition: background-color 1s;
}

.pg-filter-btn,
.pg-requester-btn-gp,
.pg-helper-btn-gp {
  /* padding: 0.3em; */
  border-radius: 10px;
  border: 1px solid #fff;
}

.requester-btn,
.helper-btn {
  cursor: pointer;
  transition: background-color 1s;
  border-radius: 5px;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.pg-requester-btn-gp,
.pg-helper-btn-gp {
  padding: 1em;
}

.pg-requester-btn-gp {
  margin-bottom: 3vw;
}

/* .pg-requester-open-status-btn,
.pg-requester-resolved-status-btn,
.helper-accepted-filter-btn,
.helper-pending-filter-btn {
  padding-left: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 1vw;
  border-radius: 5px;
  transition: background-color 1s;
  cursor: pointer;
} */

.helper-accepted-filter-btn,
.helper-pending-filter-btn {
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 1vw;
  border-radius: 5px;
  transition: background-color 1s;
  cursor: pointer;
}

.pg-filter-btn:hover,
.requester-btn:hover,
.helper-btn:hover,
.pg-requester-open-status-btn:hover,
.pg-requester-resolved-status-btn:hover,
.helper-accepted-filter-btn:hover,
.helper-pending-filter-btn:hover {
  background-color: #468189;
}

.linebreak {
  width: 100%;
  margin-top: 1vw;
  border-top: 1px dashed rgba(255, 255, 255, 0.8);
}

.selected-filter-btn {
  background-color: #468189;
}

.pg-middle-section {
  border-radius: 10px;
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
}

.pg-middle-section::-webkit-scrollbar {
  display: none;
}

.pg-box {
  width: 95%;
  background: linear-gradient(to bottom, #9dbebb 0%, #468189 50%, #9dbebb 100%);
  border-radius: 1rem;
  padding: 1rem;
  margin: 0px auto;
  word-spacing: 0.3vw;
  letter-spacing: 0.05vw;
  color: inherit;
  position: relative;
  transition: box-shadow 0.5s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  box-sizing: border-box;
}

.pg-box:hover,
.box:hover {
  box-shadow: 0px 0px 15px 3px #fff;
}

.no-request-container {
  font-size: 1.3vw;
}

.pg-user {
  font-size: 1.3vw;
  text-align: center;
}

.pg-box:not(:nth-child(2)) {
  margin-bottom: 2vw;
}

.pg-catgory {
  font-size: 1.2vw;
  color: #031926;
  font-weight: 900;
  text-align: center;
}

.pg-status {
  text-align: center;
  font-size: 1.3vw;
  padding-bottom: 1rem;
  border-bottom: 3px #031926 solid; 
}

.open-status {
  color: green !important;
}

.closed-status {
  color: rgb(74, 106, 74) !important;
}

.pg-des-box {
  font-size: 1.6vw;
  margin-top: 0.6vw;
  text-align: center;
  padding: 1rem;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

.pg-content {
  color: #031926;
  line-height: 1.3em;
}

.pg-light, .all-req-lighter-text {
  color: rgba(3, 25, 38, 0.65);
}

.pg-right-side-bar {
  border-left: 1px solid #fff;
}

.user-info-display {
  position: relative;
}

.avatar-container {
  width: 10vw;
  height: 10vw;
  margin: 0px auto;
  cursor: pointer;
}

.pg-profile {
  width: inherit;
  height: inherit;
}

.user-profile {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.user-info-display {
  margin: 0px 20px;
}

.general-info {
  margin: 2.5vw;
  font-size: 1.2vw;
}

.edit-delete-btn-gp {
  position: absolute;
  left: 40vw;
  top: 1.1vw;
  font-size: 1.1vw;
}

.pg-edit-btn,
.pg-delete-btn {
  display: inline-block;
  border: 1px solid white;
  cursor: pointer;
  padding: 0.3em;
  border-radius: 5px;
  transition: background-color 0.5s;
  background-color: transparent;
}

.pg-edit-btn:hover,
.pg-delete-btn:hover {
  background-color: #468189;
}

.pg-edit-btn {
  margin-right: 2vw;
}

@media screen and (width > 1440px) {
  .edit-delete-btn-gp {
    top: 0.75vw;
  }
}

.text-me {
  font-size: 7rem;
}

.private-chat-room {
  position: absolute;
  right: 4vw;
}

@media screen and (width > 1440px) {
  .private-chat-room {
    right: 4.5vw;
  }
}

.edit-textarea-input {
  resize: horizontal;
}

.image-problem-div {
  width: 60%;
  height: 28vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.image-problem {
  width: inherit;
  height: inherit;
  object-fit: contain;
}

.edit-request-img-input-container {
  margin-top: 1.7vh;
}

.update-image-preview {
  width: 60%;
  height: 21vh;
  margin-top: 2vh;
}

.edit-request-form-container:not(:has(.update-image-preview))
  .edit-request-btn {
  margin-top: 4vh;
}

.edit-request-img-input-container {
  margin-bottom: 2em;
}

.user-profile-preview-container {
  width: 60%;
  max-height: 20%;
  margin-bottom: 1em;
}

.user-profile-image {
  width: inherit;
  height: inherit;
}