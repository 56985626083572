.offer-container {
  /* position: relative; */

  /* background-color: aliceblue; */
  /* height: 17rem; */
  /* border-radius: 50%; */
  /* box-shadow: 0px 0px 50px #F4E9CD; */
  margin: 1rem;
  /* margin-bottom: 2rem; */
  /* margin-right: -3rem; */
  letter-spacing: 0.05em;
}

.random-dummy-css-container {
  color: blue;
}

.one-req-offers-container:has(*) {
  background-color: #9dbebb;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  margin-bottom: 2em;
}

.one-req-offers-container:first-child {
  border-top: none;
}

.offer-container-on-notify:last-child {
  margin-bottom: 2em;
}

.offer-container-on-notify .pad {
  font-size: 1.2vw;
}

.on-notify-dim {
  color: rgb(212, 195, 150);
}

.on-notify-offer {
  padding-top: 0;
}

.on-notify-offer-status-container {
  font-size: 1.45vw !important;
}

.on-notify-offer-status {
  color: rgb(68, 206, 4);
  /* font */
}

.delete-offer-trash-can-btn {
  color: rgb(193, 190, 190) !important;
  margin-bottom: 0.2em;
  cursor: pointer;
}

.on-notify-helper {
  padding-bottom: 0.5em;
  border-bottom: 2px solid #9dbebb;
}

.on-notify-offer-text {
  font-size: 1.45vw;
}

.offer-container-on-notify {
  /* border: 1px solid #031926; */
  background-color: #4E868D;
  border-radius: 5px;
  padding: 1em;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.all-offers-scrollable-container {
  width: 100%;
  height: 50%;
}

.offer-box {
  border: 2px solid #acbbb9;
  margin-bottom: 2em;
  border-radius: 10px;
  background-color: #1c2d39;
}

.req-offer-container {
  /* background-color: #9dbebb; */
  /* margin-top: 3rem; */
  padding-top: 3rem;
  /* color:#031926; */
  overflow-x: hidden;
}

.req-offer-container .notify-modal-problems .box{
  width: 80%;
  box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.9);
}

.req-offer-container .notify-modal-problems .box:hover {
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.9);
}

.bigger {
  /* padding: .5rem; */
  font-size: 1.2vw;
  /* box-shadow: 0px 0px 50px #F4E9CD; */
}

.green {
  color: green;
  font-size: x-large;
}

.pad {
  margin: 1rem;
  /* background-color:#9DBEBB ; */
}

.close {
  /* color:red; */
  font-size: x-large;
  text-align: right;
}

.des-box-dim {
  color: rgb(194, 182, 151);
}

.des-box-lightweight {
  font-weight: 100 !important;
}

.pending-offer-content {
  padding-top: 0;
}

.open-req-dim {
  color: rgb(212, 195, 150);
}

.open-req-modal-status {
  color: rgb(65, 200, 2);
}

.open-req-modal-content {
  font-size: 1.2vw;
  line-height: 1.3em;
}

.accepted-offers {
  margin: auto;
  margin-top: 5rem;
  width: 50%;
}

.accepted-offer-dim {
  color: rgb(212, 195, 150);
}

.accepted-offer-content {
  font-size: 1.3vw !important;
}

.accepted-offer-content:nth-child(4) {
  padding-top: 0;
}

.req-modal-requester-link {
  color: #F4E9CD !important;
}

.req-modal-dim {
  color: rgb(212, 195, 150);
}

.center {
  align-items: center;
  text-align: center;
  align-content: center;
}

.review {
  /* background-color: #9DBEBB; */
  padding: 0.5rem;
  border: 2px solid #60968f;
  background-color: #95b1af;
  border-radius: 10px;
  color: #031926;
  margin: 1em;
  font-size: 1.2vw;
  /* text-align: start; */

  /* background-color: whitesmoke; */
}

.req {
  font-size: xx-large;
  cursor: pointer;
  color: #9dbebb;
}

.accepted-offers-fab {
  font-size: 3em;
  position: fixed;
  left: 67vw;
  bottom: 10vw;
}

.edit-delete{
  text-align: end;
  /* background-color: aliceblue; */
  display: inline-block;
  /* margin-left: 18rem; */
}

.padding{
padding: .3rem;
cursor: pointer;
}

.pending{
  background-color:#1c2d39 ;
  border: 2px solid #acbbb9;
  text-align: center;
  padding: 3rem;
  position: relative;
  border-radius: 1rem;
}

.pending-offer-status-container {
  font-size: 1.6vw;
  font-family: 'Times New Roman', Times, serif;
}

.pending-offer-status {
  font-weight: bold;
}

.problem-image{
  width: 70%;
}

.user-review{
  display: flex;
  color: #1c2d39;
  width: 100%;
  text-align: center;
  align-items: center;
}

.space-review{
  /* display: flex; */
  /* justify-content: space-between; */
  /* background-color: #031926; */
  text-align: left;
  padding: 0 2.2em;
  margin-bottom: 0.5em;
}

.pending-offer-edit-delete{
  /* background-color: #031926; */
  width: 30%;
  margin-left: -6.5em;

}

@media screen and (width >= 1440px) {
  .pending-offer-edit-delete {
    margin-left: -7em;
  }
}

@media screen and (width < 1440px) {
  .pending-offer-edit-delete {
    margin-left: -8em;
  }
}

@media screen and (width < 1300px) {
  .pending-offer-edit-delete {
    margin-left: -9em;
  }
}

@media screen and (width < 1100px) {
  .pending-offer-edit-delete {
    margin-left: -11em;
  }
}

@media screen and (width < 800px) {
  .pending-offer-edit-delete {
    margin-left: -13em;
  }
}

@media screen and (width < 600px) {
  .pending-offer-edit-delete {
    margin-left: -16em;
  }
}

/* .price-input {
  outline: none;
} */

.reviewer-image-username {
  display: inline-block;
}

.reviewer-image-username:nth-child(2) {
  margin-left: 0.5em;
  color: rgba(28, 45, 57, 0.8);
}

.no-pending-offers-container,
.no-accepted-offers-container {
  width: fit-content;
  margin: 15vw auto;
  font-size: 1.5vw;
  color: #F4E9CD;
}

.request-modal-img-container {
  width: 80%;
  margin: 0 auto;
}

.request-modal-img {
  width: inherit;
}